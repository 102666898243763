import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styles from "./index.module.scss"
import Img from "gatsby-image"

const RelatedPost = props => {
  const data = useStaticQuery(
    graphql`
      query {
        allMdx(filter: { frontmatter: { type: { eq: null } } }) {
          nodes {
            id
            excerpt(pruneLength: 160)
            fields {
              slug
            }
            frontmatter {
              title
              date(formatString: "YYYY.MM.DD")
              description
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 1920, webpQuality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              categories
            }
          }
        }
      }
    `
  )
  const allPost = data.allMdx.nodes
  let relatedPosts = []
  const categories = props.post.frontmatter.categories

  categories.map(cat => {
    allPost.map(post => {
      const postCat = post.frontmatter.categories
      postCat.map(postC => {
        if (postC === cat) {
          relatedPosts.push(post)
        }
        return null
      })
      return null
    })
    return null
  })
  relatedPosts = relatedPosts.slice(0, 3)

  return (
    <div>
      <strong>Related Posts</strong>
      <div
        className="uk-child-width-1-3@s uk-flex-center uk-grid-match"
        uk-grid="true"
        uk-height-match="target: > div > div > .uk-card-body; row: false"
      >
        {relatedPosts.map(post => {
          return (
            <div
              className="uk-margin-auto uk-padding-remove　uk-grid-item-match"
              key={post.id}
            >
              <div
                className="uk-card uk-card-hover uk-card-default uk-card-small "
                id={styles.card}
              >
                <div className="uk-card-media-top uk-cover-container">
                  <div className="uk-position-top-center" uk-cover="true">
                    <Link to={post.fields.slug}>
                      <div
                        className="uk-position-top-center"
                        uk-cover="true"
                        aria-label="post"
                      >
                        <Img
                          fluid={
                            post.frontmatter.thumbnail.childImageSharp.fluid
                          }
                        />
                      </div>
                    </Link>
                  </div>
                  <canvas height="300" width="500" />
                </div>
                <div className="uk-card-header" id={styles.card_header}>
                  <Link to={post.fields.slug}>
                    <h5 className="uk-card-title" id={styles.post_title}>
                      {post.frontmatter.title}
                    </h5>
                  </Link>
                </div>
                <div
                  className="uk-card-body uk-flex uk-flex-column uk-child-margin-small-bottom"
                  id={styles.card_body}
                ></div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default RelatedPost
