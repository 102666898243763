import React from "react"
import Helmet from "react-helmet"
import { Link, graphql } from "gatsby"
import moment from "moment-timezone"
import Disqus from "gatsby-plugin-disqus"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../../components/layout/Layout"
import Header from "../../components/header"
import SnsShare from "../../components/snsShare"
import CategoryLabel from "../../components/categoryLabel"
import TagLabel from "../../components/tagLabel"
import RelatedPost from "../../components/relatedPost"
import Meta from "../../components/meta"

import styles from "./index.module.scss"
import "./index.scss"
import "../../styles/prism-onedark.css"
import "prismjs/plugins/line-numbers/prism-line-numbers.css"

import LinkCard from "../../components/linkCard"
import Ad from "../../components/adsense"
const shortcodes = { LinkCard, Ad }

export const BlogPostTemplate = ({ post, previous, next }) => {
  const url = "https://jackjasonb.com" + post.fields.slug

  return (
    <section className="section" id={styles.content}>
      {post.frontmatter.categories ? (
        <div id={styles.label}>
          <strong>Category</strong>
          <CategoryLabel post={post} />
        </div>
      ) : null}
      {post.frontmatter.tags ? (
        <div id={styles.label}>
          <strong>Tags</strong>
          <TagLabel post={post} />
        </div>
      ) : null}
      <hr id={styles.divide} />
      <Ad slot="2832953147" />
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{post.body}</MDXRenderer>
      </MDXProvider>
      <Ad slot="2832953147" />

      <hr />
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
      <div>
        <hr />
        <span uk-icon="icon: clock" />
        {post.frontmatter.date}
        &nbsp;&nbsp;
        <span uk-icon="icon: future" />
        {post.frontmatter.modified}
      </div>
      <div className="contentFooter">
        <div className="share">
          <strong>Share</strong>
          <SnsShare title={post.frontmatter.title} url={url} />
        </div>
        <hr id={styles.divide} />
        <RelatedPost post={post} />
        <hr id={styles.divide} />
        <div className="comment">
          <strong>Comment</strong>
          <Disqus
            identifier={post.fields.slug}
            title={post.frontmatter.title}
            url={url}
          />
        </div>
      </div>
    </section>
  )
}

const BlogPost = props => {
  const { data } = props
  const { mdx: post, previous, next } = data

  post.frontmatter.date = moment(post.frontmatter.date)
    .tz("Asia/Tokyo")
    .format("YYYY.MM.DD HH:mm")
  post.frontmatter.modified = moment(post.frontmatter.modified)
    .tz("Asia/Tokyo")
    .format("YYYY.MM.DD HH:mm")

  const description = (
    <>
      <span uk-icon="icon: clock" className={styles.ukIcon} />
      {post.frontmatter.date}
      <span uk-icon="icon: future" className={styles.ukIcon} />
      {post.frontmatter.modified}
    </>
  )
  const header = (
    <Header
      title={post.frontmatter.title}
      description={description}
      image={post.frontmatter.thumbnail.childImageSharp.fluid}
      loc={post.fields.slug}
    />
  )
  var metaDescription =
    post.excerpt
      .replace(/\r?\n/g, "")
      .replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, "")
      .trim() + "..."
  return (
    <Layout loc={post.fields.slug} header={header}>
      <Helmet
        title={`${post.frontmatter.title} | ${data.site.siteMetadata.title}`}
      />
      <Meta
        isRoot={false}
        title={`${post.frontmatter.title} | ${data.site.siteMetadata.title}`}
        imageUrl={post.frontmatter.thumbnail.publicURL}
        description={metaDescription}
      />
      <BlogPostTemplate post={post} previous={previous} next={next} />
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(id: { eq: $id }) {
      id
      excerpt(pruneLength: 120)
      body
      frontmatter {
        title
        date
        description
        categories
        modified
        tags
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 1920, webpQuality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
        }
      }
      fields {
        slug
      }
    }
    previous: mdx(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: mdx(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
