import React from "react"
import AdSense from "react-adsense"

const Ad = ({ slot, format = "auto", responsive = "false", layout = "" }) => {
  return (
    <AdSense.Google
      client="ca-pub-6113326314551329"
      slot={slot}
      format={format}
      responsive={responsive}
      layout={layout}
      style={{ display: "block", maxWidth: "850px", backgroundColor: "white" }}
    />
  )
}

export default Ad
