import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const TagLabel = props => {
  const { post } = props
  const { tags } = post.frontmatter

  const data = useStaticQuery(
    graphql`
      query {
        allTagJson {
          nodes {
            name
            slug
          }
        }
      }
    `
  )
  const { nodes: ts } = data.allTagJson

  return (
    <div key={`${post.id} tags`}>
      {tags && tags.length ? (
        <div>
          <span uk-icon="icon: tag" />
          {tags.map(tag => {
            let link = "/"
            for (let i = 0; i < ts.length; i++) {
              if (ts[i].name === tag) {
                link = link + "tag/" + ts[i].slug
                break
              }
            }
            return (
              <Link
                className="uk-label uk-margin-small-right uk-text-truncate"
                to={link}
              >
                {tag}
              </Link>
            )
          })}
        </div>
      ) : null}
    </div>
  )
}

export default TagLabel
